<template>
    <col :o365-field="colId ?? field">
</template>

<script lang="ts">
import type { default as BaseColumn, ICellRenderer, ICellEditor, IFilterComponent, IFilterParams } from 'o365.controls.Grid.BaseColumn.ts';

declare module 'vue' {
    interface ColHTMLAttributes {
        o365Field?: string
    }
}

export interface IColumnProps {
    colId?: string;
    field?: string;
    width?: number | string;
    hide?: boolean;
    editable?: boolean;
    order?: number;
    pinned?: 'left'|'right';
    type?: 'string'|'boolean'|'bit'|'number'|'date'|'datetime'|'uniqueidentifier';
    suppressMovable?: boolean;
    suppressSelection?: boolean;
    disableResizable?: boolean;
    minWidth?: number | string;
    maxWidth?: number | string;
    left?: number;
    cellClass?: any | ((column: BaseColumn) => any);
    cellStyle?: any | ((column: BaseColumn) => any);
    cellRenderer?: ICellRenderer | string;
    cellRendererParams?: any;
    cellEditor?: ICellEditor | string;
    cellEditorParams?: any;
    headerName?: string;
    headerTitle?: string;
    disableMenu?: boolean;
    suppressNavigable?: boolean;
    dblclickHandler?: Function;
    filter?: IFilterComponent;
    disableFilter?: boolean;
    filterDropdown?: IFilterComponent;
    filterParams?: IFilterParams;
    sortable?: boolean;
    sort?: 'asc' | 'desc';
    sortOrder?: number;
    format?: string;
    disableDefaultClass?: boolean;
    cellTitle?: string | ((row: any) => string);
}; 
</script>

<script lang="ts" setup>
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { onUnmounted, useAttrs, useSlots } from 'vue';

const props = defineProps<IColumnProps>();
const attrs = useAttrs();
const slots = useSlots();

const gridControl = useBaseGridControl();

gridControl.value.columns.addColumn({
    ...props,
    cellClass: props.cellClass ?? attrs.class,
    cellStyle: props.cellStyle ?? attrs.style,
    slots: slots,
});

onUnmounted(() => {
    if (gridControl.value.isBeingUnmounted) { return; }
    gridControl.value.columns.removeColumn(props.colId ?? props.field);
});
</script>